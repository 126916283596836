<template>
  <v-container fluid id="regular-table">
    <v-form ref="form" v-model="is_valid">
      <div class="d-flex flex-column flex-md-row" style="gap: 150px">
        <div class="flex-grow-1 ma-4" style="max-width: 700px">
          <h4 class="text-h4 font-weight-medium mb-4">
            {{ $t("supplyOrders.order_details") }}
          </h4>

          <v-autocomplete
            outlined
            clearable
            dense
            :label="$t('supplyOrders.shelf')"
            :items="shelves"
            v-model="supplyOrder.shelf_id"
            item-value="id"
            item-text="name"
            :loading="isLoadingShelves"
            @change="handleShelfChange"
          />
          <v-autocomplete
            outlined
            dense
            :label="$t('supplyOrders.supplier')"
            :items="listProviders"
            v-model="supplyOrder.from_provider_id"
            item-value="id"
            item-text="name"
            :loading="isLoadingProviders"
            :rules="[rules.required]"
            @change="handleSupplierChange"
            :search-input.sync="cleanList"
          />
        </div>
        <div div class="flex-grow-1 ma-4" style="max-width: 700px">
          <h4 class="text-h4 font-weight-medium mb-4">
            {{ $t("supplyOrders.delivery_details") }}
          </h4>
          <v-autocomplete
            outlined
            dense
            :label="$t('supplyOrders.delivery_type')"
            :items="
              listTypes.map((listType) => ({
                ...listType,
                value: listType.name,
                name: listType.name.toUpperCase(),
              }))
            "
            item-value="value"
            item-text="name"
            v-model="supplyOrder.delivery_type"
            @change="handleDeliveryTypeChange"
            :rules="[rules.required]"
            persistent-hint
          />
          <v-autocomplete
            outlined
            dense
            :label="$t('supplyOrders.warehouse')"
            :items="hubs"
            item-value="id"
            item-text="name"
            v-model="supplyOrder.to_hub_id"
            :rules="[rules.required]"
            @change="handleWarehouseChange"
          />
        </div>
      </div>
    </v-form>
    <v-card class="mt-n1">
      <v-toolbar dark tabs flat color="#5E2FBE" class="hidden-xs-only">
        <template>
          <v-tabs v-model="tabs">
            <v-tab href="#product">
              <v-icon>mdi-format-list-bulleted</v-icon>&nbsp;
              {{ $t("supplyOrders.product_list") }}
            </v-tab>
            <v-tab href="#cart">
              <v-badge
                v-if="supplyOrder.products?.length"
                color="green"
                :content="supplyOrder.products?.length"
              >
                <v-icon>mdi-cart</v-icon>&nbsp;
                {{ $t("supplyOrders.item_cart") }}
              </v-badge>
              <span v-else>
                <v-icon>mdi-cart</v-icon>&nbsp;
                {{ $t("supplyOrders.item_cart") }}</span
              >
            </v-tab>
            <v-tabs-slider color="white"></v-tabs-slider>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>
    <v-card-text>
      <v-tabs-items v-model="tabs">
        <v-tab-item value="product">
          <v-row class="mt-n1">
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                outlined
                dense
                :label="$t('search')"
                v-model="options.search"
                class=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_parent_id"
                :items="categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadcategory()"
                :search-input.sync="search_from_category"
                :label="$t('category')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_id"
                :items="sub_categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingSubCategories"
                :label="$t('sub_category')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listProducts"
            :loading="isLoadingProducts"
            item-key="id"
            class="elevation-4"
            :options.sync="options"
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.purchase_price="{ item }">
              {{ Number(item.purchase_price).toFixed(2) }}
            </template>
            <template v-slot:item.qte_in_hub="{ item }">
              <span class="font-weight-medium">{{
                item.qte_in_hub ?? "-"
              }}</span>
            </template>
            <template v-slot:item.moving_quantity="{ item }">
              <a @click="openMovingQuantitiesModal(item)">
                <span
                  v-if="item.moving_quantity > 0"
                  class="green--text font-weight-medium"
                  >+ {{ item.moving_quantity }}</span
                >
                <span
                  v-if="item.moving_quantity < 0"
                  class="red--text font-weight-medium"
                  >{{ item.moving_quantity }}</span
                >
              </a>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn color="#6100ea" @click="openOrderItemModal(item)"
                >ADD</v-btn
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="cart">
          <v-data-table
            :headers="cart_headers"
            item-key="name"
            :items="
              supplyOrder.products?.map((product) => {
                return {
                  ...product,
                  total_price_ttc: Number(product.total_price).toFixed(2),
                };
              })
            "
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.purchase_price="{ item }">
              {{
                Number(
                  (item.purchase_price_ht -
                    item.purchase_price_ht * (item.discount / 100)) *
                    (1 + parseFloat(item.purchase_tva) / 100)
                )?.toFixed(2)
              }}
            </template>
            <template v-slot:item.discount_price="{ item }">
              <span v-if="item.discount != 0">
                {{
                  Number(
                    item.purchase_price_ht -
                      item.purchase_price_ht * (item.discount / 100)
                  ).toFixed(2)
                }}
              </span>
              <span v-else> - </span>
            </template>
            <template v-slot:item.discount="{ item }">
              <span>{{ item.discount ?? "-" }}</span>
            </template>
            <template v-slot:item.total_units="{ item }">
              {{ calculateUnits(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                color="#C6C6C6"
                @click="openOrderItemModal(item)"
              >
                $vuetify.icons.iconEditItem
              </v-icon>
              <v-icon @click="deleteItem(item)">
                $vuetify.icons.iconDeleteItem
              </v-icon>
            </template>
          </v-data-table>
          <div class="mt-8 d-flex">
            <v-btn
              class="ml-auto primary"
              :disabled="!supplyOrder.products || supplyOrder.products <= 0"
              @click="submitSupplyOrder()"
              >{{ $t("supplyOrders.submit_order") }}</v-btn
            >
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <product-modal
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      :orderItem="currentOrderItem"
      :toggleModal="() => toggleModal()"
    ></product-modal>
    <submit-order-modal
      :isSubmitOrderModalOpen="isSubmitOrderModalOpen"
      :toggleSubmitOrder="toggleSubmitOrder"
      :currentSupplyOrder="supplyOrder"
    ></submit-order-modal>
    <moving-quantities-modal
      v-if="isMovingQuantitiesModalOpen"
      :isMovingQuantitiesModalOpen="isMovingQuantitiesModalOpen"
      :toggleMovingModal="() => toggleMovingModal()"
      :transferItem="currentTransferItem"
      :listMoving="listMoving"
    >
    </moving-quantities-modal>
    <template>
      <v-dialog v-model="isSupplierModalOpen" persistent max-width="500px">
        <v-card>
          <v-card-title class="text-h3 font-weight-medium">
            {{ $t("supplyOrders.supply_change_title") }}
          </v-card-title>
          <v-card-text
            >{{ $t("supplyOrders.change_supplier_confirmation") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" @click="close()" color="indigo" text>
              {{ $t("cancel") }}
            </v-btn>
            <v-btn class="primary" @click="confirm()" text>
              {{ $t("confirm") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> </template
  ></v-container>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import supplyOrderService from "@/store/services/supply-order-service";
import categoryService from "@/store/services/category-service";
import debounce from "lodash/debounce";
import productService from "@/store/services/product-service";
export default {
  mixins: [validationRules],
  components: {
    ProductModal: () => import("./modals/ProductModal.vue"),
    SubmitOrderModal: () => import("./modals/SubmitOrderModal.vue"),
    MovingQuantitiesModal: () =>
      import("../transfers/modals/MovingQuantitiesModal"),
  },
  computed: {
    console: () => console,
    ...mapGetters({
      shelves: "categories/listShelves",
      isLoadingShelves: "categories/isLoadingCategories",
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingSubCategories: "categories/isLoadingCategories",
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      isLoadingProviders: "providers/isLoadingProviders",
      listProviders: "providers/listProviders",
      isLoadingTypes: "supplyOrders/isLoadingTypes",
      listTypes: "supplyOrders/listTypes",
      listProductsInCart: "supplyOrders/listProducts",
    }),
    supplyOrder: {
      get() {
        return this.$store.state.supplyOrders.supplyOrder;
      },
      set(value) {
        this.$store.commit("supplyOrders/EDITED_SUPPLY_ORDER", value);
      },
    },
    cart: {
      get() {
        return this.$store.state.supplyOrders.cart;
      },
    },
  },
  data() {
    return {
      is_valid: false,
      tabs: true,
      categories: [],
      sub_categories: [],
      cleanList: null,
      cleancateg: null,
      currentOrderItem: null,
      isModalOpen: false,
      isSubmitOrderModalOpen: false,
      isSupplierModalOpen: false,
      is_loading_product: false,
      search_from_category: null,
      currentProviderId: null,
      isMovingQuantitiesModalOpen: null,
      options: {},
      headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "Produits",
          sortable: false,
          value: "name",
        },
        { text: "Product ID", value: "code" },
        { text: "SKU", value: "sku" },
        { text: "Purchase price HT", value: "purchase_price_ht" },
        { text: "VAT", value: "tva" },
        { text: "Purchase price TTC", value: "purchase_price" },
        { text: "Stock", value: "qte_in_hub" },
        { text: "Moving", value: "moving_quantity" },
        { text: "Actions", value: "actions" },
      ],
      cart_headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "Produits",
          sortable: false,
          value: "name",
        },
        { text: "Quantity (Units)", value: "total_units" },
        { text: "Purchase price HT", value: "purchase_price_ht" },
        { text: "Discount %", value: "discount" },
        { text: "Discount Price", value: "discount_price" },
        { text: "VAT %", value: "purchase_tva" },
        { text: "Purchase Price", value: "purchase_price" },
        { text: "Total price", value: "total_price_ttc" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  watch: {
    async cleanList(value) {
      if (value == null) {
        await this.$store.dispatch("products/clean");
        this.supplyOrder.products = null;
        this.supplyOrder.products_count = 0;
      }
    },
    options: {
      handler: debounce(async function () {
        await this.getProduct();
      }, 1000),
      deep: true,
    },
  },
  methods: {
    loadcategory: debounce(async function (search) {
      const categories = await categoryService.list({
        parent_id: this.supplyOrder.shelf_id,
        level: 2,
        search,
      });
      this.categories = categories;
      this.options.category_id = null;
      if (this.options.category_parent_id) {
        const sub_categories = await categoryService.list({
          level: 3,
          parent_id: this.options.category_parent_id,
          search,
        });
        this.sub_categories = sub_categories;
      }
    }, 1000),
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    confirm() {
      if (this.supplyOrder?.id && this.supplyOrder.from_provider_id) {
        this.$store.dispatch(
          "supplyOrders/updateSupplyOrder",
          this.supplyOrder
        );
      }
      this.currentProviderId = this.supplyOrder.from_provider_id;
      this.getProduct();
      this.toggleSupplier();
    },
    close() {
      this.supplyOrder.from_provider_id = this.currentProviderId;
      this.toggleSupplier();
    },
    async loadProviders() {
      try {
        await this.$store.dispatch("providers/list", {
          shelf_id: this.supplyOrder.shelf_id,
          status: "active",
        });
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    async LoadingTypes() {
      try {
        await this.$store.dispatch("supplyOrders/types", {});
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    async getWarehouse() {
      console.log(this.supplyOrder.delivery_type);
      if (this.supplyOrder.delivery_type == "direct") {
        try {
          await this.$store.dispatch("hubs/list", { for: "inbound" });
        } catch (error) {
          if (error.response?.status == "401") {
            await this.$store.dispatch("logout");
          }
          await this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message
          );
        }
      } else if (this.supplyOrder.delivery_type == "central")
        await this.$store.dispatch("hubs/list", { type: "central" });
    },
    async openMovingQuantitiesModal(item) {
      const info = await productService.movingQuantities({
        product_id: item.id,
        hub_id: this.supplyOrder.to_hub_id,
      });
      this.listMoving = info;
      this.currentTransferItem = item;
      this.isMovingQuantitiesModalOpen = true;
    },
    toggleMovingModal() {
      this.isMovingQuantitiesModalOpen = !this.isMovingQuantitiesModalOpen;
    },
    async getProduct() {
      if (this.supplyOrder.from_provider_id) {
        await this.$store.dispatch("products/list", {
          search: this.options.search,
          category_parent_id: this.options.category_parent_id,
          category_id: this.options.category_id,
          shelf_id: this.supplyOrder.shelf_id,
          provider_id: this.supplyOrder.from_provider_id,
          with_stock: true,
          hub_id: this.supplyOrder.to_hub_id,
          with_moving: true,
        });
      }
    },
    async loadShelves() {
      try {
        await this.$store.dispatch("categories/listShelves");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    async init() {
      await this.loadcategory();
      await this.loadShelves();
      await this.loadProviders();
      await this.LoadingTypes();
    },
    openOrderItemModal(item) {
      this.validate();
      if (this.is_valid) {
        this.currentOrderItem = item;
        if (this.currentOrderItem.discount == null)
          this.currentOrderItem.discount = 0.0;
        if (item.total_price == null) {
          this.supplyOrder.products?.forEach((value) => {
            if (item.code == value.code) {
              this.currentOrderItem.qte_by_type_packaging =
                value.qte_by_type_packaging;
              this.currentOrderItem.discount = value.discount;
              this.currentOrderItem.type_packaging = value.type_packaging;
            }
          });
        }
        this.isModalOpen = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    deleteItem(item) {
      this.$store.dispatch("supplyOrders/removeFromCart", {
        ...item,
        supply_order_id: this.supplyOrder.id,
      });
    },
    toggleSubmitOrder() {
      this.isSubmitOrderModalOpen = !this.isSubmitOrderModalOpen;
    },
    submitSupplyOrder() {
      this.toggleSubmitOrder();
    },
    toggleSupplier() {
      this.isSupplierModalOpen = !this.isSupplierModalOpen;
    },
    calculateUnits(item) {
      switch (item.type_packaging) {
        case "unit":
          return item.qte_by_type_packaging;
        case "pack":
          return Number(
            item.qte_by_type_packaging * item.product_info.package_units
          );
        case "pallet":
          return Number(
            item.qte_by_type_packaging *
              item.product_info.package_units *
              item.product_info.palette_units
          );
        default:
          return 1;
      }
    },
    handleShelfChange() {
      this.loadProviders();
      this.loadcategory();
      this.supplyOrder.from_provider_id = null;
      this.options.category_parent_id = null;
      if (
        this.supplyOrder?.id &&
        this.supplyOrder.from_provider_id &&
        this.supplyOrder.shelf_id
      ) {
        this.$store.dispatch(
          "supplyOrders/updateSupplyOrder",
          this.supplyOrder
        );
      }
    },
    handleSupplierChange() {
      if (this.supplyOrder.products_count == null)
        this.supplyOrder.products_count = 0;
      if (this.supplyOrder.products_count != 0) {
        this.isSupplierModalOpen = true;
      } else {
        if (this.supplyOrder?.id && this.supplyOrder.from_provider_id) {
          this.$store.dispatch(
            "supplyOrders/updateSupplyOrder",
            this.supplyOrder
          );
        }
        this.currentProviderId = this.supplyOrder.from_provider_id;
        this.getProduct();
      }
    },
    handleDeliveryTypeChange() {
      this.getWarehouse();
      if (this.supplyOrder?.id && this.supplyOrder.from_provider_id) {
        this.$store.dispatch(
          "supplyOrders/updateSupplyOrder",
          this.supplyOrder
        );
      }
    },
    handleWarehouseChange() {
      this.getProduct();
      if (
        this.supplyOrder?.id &&
        this.supplyOrder.from_provider_id &&
        this.supplyOrder.shelf_id
      ) {
        this.$store.dispatch(
          "supplyOrders/updateSupplyOrder",
          this.supplyOrder
        );
      }
    },
  },
  async created() {
    this.$store.commit("supplyOrders/SupplyOrder", {});
    this.$store.commit("products/CLEAN_LIST");
    await this.init();
    const id = this.$route.params.id;
    if (id) {
      const supplyOrderEntity = await supplyOrderService.show({
        id,
      });
      this.$store.commit("supplyOrders/SupplyOrder", supplyOrderEntity);
      this.getWarehouse();
      this.getProduct();
    }
  },
};
</script>